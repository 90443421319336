<template>
  <div class="page">
    <el-form
      ref="formData"
      label-position="right"
      :model="formData"
      label-width="80px"
    >
      <el-form-item label="logo" prop="logo" label-width="120px">
        <el-upload
          action="https://bkgaoshou.com/index.php/admin/upload/images"
          :show-file-list="false"
          :on-success="upload_logo"
        >
          <i class="el-icon-plus"></i>
          <img
            v-if="formData.logo"
            :src="formData.logo"
            class="avatar"
            style="max-height: 100px"
          />
        </el-upload>
      </el-form-item>
      <el-button
        type="primary"
        @click="add"
        size="mini"
        style="margin-bottom: 50px;margin-left:100px;margin-top:30px"
        >确定</el-button
      >
    </el-form>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      formData: {
        logo: "",
      },
    };
  },
  methods: {
    getData() {
      this.$fecth
        .post("system_setting/getDataById", {
           
        })
        .then((res) => {
          const { code, msg,data } = res;
          if (code == 200) {
            this.formData = data
          } else {
            this.showTips(0, msg);
          }
        });
    },
    add() {
      this.$fecth
        .post("system_setting/edit", {
            logo: this.formData.logo,
        })
        .then((res) => {
          const { code, msg } = res;
          if (code == 200) {
            this.showTips(1, '设置成功');
          } else {
            this.showTips(0, msg);
          }
        });
    },
    upload_img(file) {
      if (file.code == 200) {
        this.formData.vip_banner = file.data;
      } else {
        this.showTips(0, file.data);
      }
    },
    upload_score(file) {
      if (file.code == 200) {
        this.formData.score_banner = file.data;
      } else {
        this.showTips(0, file.data);
      }
    },
    upload_logo(file) {
      if (file.code == 200) {
        this.formData.logo = file.data;
      } else {
        this.showTips(0, file.data);
      }
    },
    showTips(flag, msg) {
      let title = flag == 1 ? "成功" : "失败";
      let type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.page {
  background: white;
}
</style>
